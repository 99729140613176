<template>
<div class="action-block">
  <div class="common-action dark-gray" @click="showModal">
    <i :class="block.icon"></i> {{  block.name  }}
  </div>

  <vuestic-modal 
    id="collect-contact-data-modal"
    :isOpen="isShowModal"
    @cancel="isShowModal = false"
    @ok="save"
    :cancelShown="false"
    :force="true"
    okText="Save"
  >
    <span slot="title" class="text-primary font-weight-bold">Collect Contact Data</span>
    <div class="mb-4">
      <text-input v-model="block.name"></text-input>
    </div>
    <div class="field-wrapper">
      <div v-for="(input, index) in block.options.collectFields" :key="`input-${index}`">
        <div class="mb-2">
          <div class="custom-checkbox-wrapper d-inline-block">
            <label class="checkbox-label">
              <input type="checkbox" :id="input.name" v-model="input.enable">
              <span class="checkbox-custom"></span>
            </label>
          </div>
          <span class="d-inline-block ml-2" style="cursor: pointer" @click="input.enable = !input.enable">{{ input.label }}</span>
        </div>
        <div v-if="input.enable" class="mb-3 d-flex">
          <TextareaEmojiPicker
            v-model="input.message"
            :enabled-mms="false"
            :show-send-button="false"
            :rows="2"
            class="flex-fill"
          />
          <select v-if="['birthday', 'anniversary'].includes(input.name) || input.type == 3" v-model="input.format" class="form-control ml-2" style="width:140px;">
            <option selected value="mm/dd">mm/dd</option>
            <option value="dd/mm">dd/mm</option>
          </select>
        </div>
      </div>
    </div>
  </vuestic-modal>
</div>
</template>

<script>
import TextareaEmojiPicker from '../../../../common/TextareaEmojiPicker'

export default {
  components: {
    TextareaEmojiPicker,
  },

  props: {
    block: {
      type: Object,
      default: null,
    }
  },


  data() {
    return {
      isShowModal: false,
    }
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },

    isTemplate() {
      return this.user && this.user.business && this.user.business.is_template
    },
    contactFields() {
      return this.$store.getters["auth/contactFields"];
    },
  },

  mounted() {
    if (this.block.isNew) {
      this.block.isNew = false
      this.showModal()
    }
  },

  methods: {
    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    }
  }
}
</script>

<style lang="scss">
#collect-contact-data-modal {
  .checkbox-label {
    --primaryColor: #3578c6;
  }
  .custom-checkbox-wrapper .checkbox-label .checkbox-custom {
    border-color: $text-gray;
    top: 4px;
    height: 17px;
    width: 17px;
  }

  .custom-checkbox-wrapper .checkbox-label input:checked ~ .checkbox-custom::after {
    left: 5px;
    top: 0px;
  }

  select {
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    background: initial;
  }
  .field-wrapper {
    max-height: 75vh;
    overflow: auto;
    padding: 10px 0 30px;
  }
}
</style>